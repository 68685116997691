function AlertMessage(type, message) {
    const alertContainer = document.getElementById("alert-container")
    var alert = document.createElement("div");
    var close = document.createElement("a");

    alert.classList.add("alert", "alert-dismissible", type);
    alert.innerHTML = message

    close.setAttribute("class", "close")
    close.setAttribute("href", "#")
    close.setAttribute("data-dismiss", "alert")
    close.setAttribute("aria-label", "close")
    close.innerHTML = "&times";
    
    alert.appendChild(close);
    alertContainer.appendChild(alert);
}
var confirm_password = document.getElementById("confirm_password");

if(confirm_password){
    var password = document.getElementById("password")
    function validatePassword(){
        if(password.value != confirm_password.value) {
          confirm_password.setCustomValidity("Passwords Don't Match");
        } else {
          confirm_password.setCustomValidity('');
        }
      }
       
      password.onchange = validatePassword;
      confirm_password.onkeyup = validatePassword;
}

async function Login(e) {
    e.preventDefault();
    const spinner = $("#loginform .loading");

    spinner.toggleClass("hidden");
    const client = new Facilis.coreClient.default;
    client.setUrl(apiUrl);

    try {
        await client.login(
            e.target[EMAIL].value,
            e.target[PASSWORD].value
        )

        let redirectUrl = e.target[RETURNURL].value;
        if (redirectUrl == 'back'){
            window.history.back();
        }
        else{
            window.location.href = redirectUrl || accountUrl;
        }
    }
    catch (error){
        const msg = loginErrors[error.message] && loginErrors[error.message][siteLang]
                      || loginErrors.default[siteLang]
                      || loginErrors.error;
        AlertMessage("alert-danger", msg);
    }
    finally{
        spinner.toggleClass("hidden");
    }
}

async function ResetPassword(e) {
    e.preventDefault();
    const spinner = $("#reset-password-form .loading");
    spinner.toggleClass("hidden");

    const client = new Facilis.coreClient.default;
    client.setUrl(apiUrl);
    
    try {
        await client.resetPassword(
            e.target[EMAIL].value,
            siteLang,
            "bys"
        )

        AlertMessage("alert-success", resetPasswordMessage[siteLang]);
    }
    catch (error){

        if (error.message == "api.error.element.not_found"){
            AlertMessage("alert-success", resetPasswordMessage[siteLang]);
        }
        else{
            const msg = resetPasswordErrors.default[siteLang] || resetPasswordErrors.error;
            AlertMessage("alert-danger", msg);
        }
    }
    finally{
        spinner.toggleClass("hidden");
    }
}

async function SetPassword(e) {
    e.preventDefault();
    const spinner = $("#set-password-form .loading");
    spinner.toggleClass("hidden");

    const client = new Facilis.coreClient.default;
    client.setUrl(apiUrl);

    try {
        await client.setPassword(
            e.target[TOKEN].value,
            e.target[EMAIL].value,
            e.target[PASSWORD].value,
            e.target[CONFIRMPASSWORD].value
        )

        AlertMessage("alert-success", setPasswordMessage[siteLang]);

        setTimeout(function(){ window.location.href = "/connect"; }, 2000);
    }
    catch (error){
        const msg = setPasswordErrors[error.message] && setPasswordErrors[error.message][siteLang]
                      || setPasswordErrors.default[siteLang]
                      || setPasswordErrors.error;
        AlertMessage("alert-danger", msg);
    }
    finally{
        spinner.toggleClass("hidden");
    }
}

// Join a bys session
async function Join(e, sessionNumber, username, method='web', passcode = '') {
    if(e){
        e.preventDefault();
        sessionNumber = e.target[SESSION].value;
        username = e.target[USERNAME].value;
        passcode = e.target[PASSWORD].value;
        method = e.target[METHOD].value;
    }
    
    const client = new Facilis.routerClient.default;
    client.setUrl(routerUrl);

    const session = await client.joinBysSession(
        sessionNumber,
        username,
        siteLang,
        passcode,
        method
    );

    if (session.active){
        window.localStorage.setItem(JOINNAME, username);

        if(method == 'web'){
            var url = session.web_url
        }else {
            var url = session.launcher_url;

            message = userMessage['download.mybys'][siteLang];
            message = message.replace(/{url}/g, url);
            AlertMessage("alert-success", message);
        }

        window.location.href = url;
    }
    else{
        const error = joinErrors[session.error] && joinErrors[session.error][siteLang]
                      || joinErrors.default[siteLang]
                      || joinErrors.error
        AlertMessage("alert-warning", error);
    }
}

// Join a Webcall
function Webcall(e, sessionNumber, joinName, returnURL) {
    if(e){
        e.preventDefault();
        sessionNumber = e.target[SESSION].value;
        joinName = e.target[JOINNAME].value;
        returnURL = e.target[RETURNURL].value;
    }

    window.localStorage.setItem(WEBCALLNAME, joinName);
    const url = `${expressplusUrl}/?conferenceID=${sessionNumber}&name=${joinName}&lang=${siteLang}&returnURL=${returnURL}`
    
    window.location.href = url;
}

// Check a bys session status and add password field if necessary
async function sessionStatus(e, sessionNumber = null) {
    if(e){
        sessionNumber = e.target.value;
    }

    var sevenOrEightDigits = /^[0-9]{7,8}$/;
    if(! sessionNumber.match(sevenOrEightDigits)){
        return;
    }

    const joinPasscode = document.querySelector("#joinform input[name='password']");
    const joinMethod = document.querySelector("#joinform input[name='method']");
    const client = new Facilis.routerClient.default;
    client.setUrl(routerUrl);

    const status = await client.getSessionStatus(
        sessionNumber
    );

    if(status.password){
        joinPasscode.classList.remove("disable");
    }
    else{
        joinPasscode.classList.add("disable");
    }

    if (Array.isArray(status.client_access)){
        joinMethod.value = status.client_access.includes("web") ? "web" : "desktop"
    }

    return status;
}

// Auto join a bys session
window.addEventListener('DOMContentLoaded', async function() {
    const joinForm = document.getElementById('joinform');
    if (joinForm){
        joinInputs = joinForm.elements;
        if(!joinInputs[USERNAME].value){
            joinInputs[USERNAME].value = window.localStorage.getItem(JOINNAME) || '';
        }
        const session = joinInputs[SESSION].value;
        const username = joinInputs[USERNAME].value;
        const url = new URL(window.location.href)
        const urlParams = new URLSearchParams(url.search);
        const autoconnect = urlParams.get(AUTOCONNECT) || urlParams.get('autoconnect');

        if(autoconnect && session && username){
            const status = await sessionStatus(null, session);
            if (status && status.active){
                var method = status.client_access.includes("web") ? "web" : "desktop"
                await Join(null, session, username, method);
            }
        }
    }
}, true);


// Auto join a webcall session
window.addEventListener('DOMContentLoaded', function() {
    const webcallForm = document.getElementById('webcallform');

    if (webcallForm){
        webcallInputs = webcallForm.elements;

        if(!webcallInputs[JOINNAME].value){
            webcallInputs[JOINNAME].value = window.localStorage.getItem(WEBCALLNAME) || '';
        }
        const session = webcallInputs[SESSION].value;
        const joinName = webcallInputs[JOINNAME].value;
        const returnURL = webcallInputs[RETURNURL].value;
        const url = new URL(window.location.href)
        const urlParams = new URLSearchParams(url.search);
        const autoconnect = urlParams.get(AUTOCONNECT) || urlParams.get('autoconnect');

        if(autoconnect && session && joinName){
            Webcall(null, session, joinName, returnURL);
        }
    }
}, true);
// MAIN.JS
//--------------------------------------------------------------------------------------------------------------------------------
//This is main JS file that contains custom JS scipts and initialization used in this template*/
// -------------------------------------------------------------------------------------------------------------------------------
// Template Name: Roker.
// Author: Iwthemes.
// Version 1.4 - Updated on 01 / 04 / 2015
// Website: http://www.iwthemes.com
// Email: support@iwthemes.com
// Copyright: (C) 2015
// -------------------------------------------------------------------------------------------------------------------------------

$(document).ready(function($) {

	'use strict';

	//=================================== Twitter Feed  ======================================//
    $(".twitter").tweet({
        modpath: 'assets/js/vendors/twitter/index.php',
        username: "facilis_inc",
        count: 5,
        loading_text: "Loading tweets...",
    });

    //=================================== Flikr Feed  ========================================//
    $('#flickr').jflickrfeed({
		limit: 8, //Number of images to be displayed
		qstrings: {
			id: '36587311@N08'//Change this to any Flickr Set ID as you prefer.
		},
		itemTemplate: '<li><a href="{{image_b}}" class="fancybox"><img src="{{image_s}}" alt="{{title}}" /></a></li>'
	});

	$('#flickr-aside').jflickrfeed({
		limit: 10, //Number of images to be displayed
		qstrings: {
			id: '36587311@N08'//Change this to any Flickr Set ID as you prefer.
		},
		itemTemplate: '<li><a href="{{image_b}}" class="fancybox"><img src="{{image_s}}" alt="{{title}}" /></a></li>'
	});

 	//=================================== Sticky nav ===================================//
	$("header").sticky({topSpacing:0});

	//=================================== Nav Responsive ==============================//
	/*
    $('#menu').tinyNav({
       active: 'selected'
    });
		*/
    //=================================== Parallax Efect ==============================//
  	$('.bg_parallax').parallax("50%", .12);

  	//=================================== Loader =====================================//
	jQuery(window).load(function() {
		jQuery(".status").fadeOut();
	    jQuery(".preloader").delay(1000).fadeOut("slow");
	})

    //=================================== Nav Superfish ===============================//
	$('ul.sf-menu').superfish();

	//=================================== Nav Scroll One Page===========================//

	// $('nav ul li a').click(function(){
  //       var el = $(this).attr('href');
  //       var elWrapped = $(el);
  //       scrollToDiv(elWrapped,40);
  //       return false;
  //   });
  //   function scrollToDiv(element,navheight){
	// 	var offset = element.offset();
	// 	var offsetTop = offset.top;
	// 	var totalScroll = offsetTop-navheight;
	// 		$('body,html').animate({
	// 					scrollTop: totalScroll
	// 		}, 500);
  //   }

  	//=================================== Accordion  =================================//
	$('.accordion-container').hide();
	$('.accordion-trigger:first').addClass('active').next().show();
	$('.accordion-trigger').click(function(){
		if( $(this).next().is(':hidden') ) {
			$('.accordion-trigger').removeClass('active').next().slideUp();
			$(this).toggleClass('active').next().slideDown();
		}
		return false;
	});

   	//=================================== jBar  =============================================//
	$('.jBar').hide();
	$('.jRibbon').show().removeClass('up', 500);
	$('.jTrigger').click(function(){
		$('.jRibbon').toggleClass('up', 500);
		$('.jBar').slideToggle();
	});

	//=================================== Simple slide  ====================================//
	$('.carousel').carousel();

	//=================================== Carousel Services  ===============================//
	$("#services-carousel").owlCarousel({
		autoPlay: 3200,
		items : 4,
		navigation: true,
		itemsDesktop : [1600,3],
		itemsDesktopSmall : [1024,2],
		itemsMobile : [800,1],
		pagination: false
	});

	//=================================== Carousel Works  ==================================//
 	$(".news").owlCarousel({
		autoPlay: 3200,
		items : 5,
		navigation: true,
		itemsDesktop : [1600,4],
		itemsDesktopSmall : [1024,3],
		itemsMobile : [500,1],
		pagination: true
	});

	//=================================== Carousel works-no-margin  ==================================//
 	$("#works-no-margin").owlCarousel({
		autoPlay: 3200,
		items : 4,
		navigation: false,
		itemsDesktop : [1600,4],
		itemsDesktopSmall : [1024,3],
		itemsMobile : [500,1],
		pagination: false
	});

	//=================================== Carousels Footer  =================================//
  	$(".tweet_list").owlCarousel({
			autoPlay: 4000,
			items : 1,
	    navigation: false,
	    pagination: true,
			singleItem: true
		});

	//=================================== Slide Services  ==================================//
 	$("#slide-services").owlCarousel({
		autoPlay: false,
		items : 1,
    navigation : true,
    autoHeight : true,
    slideSpeed : 400,
    singleItem: true,
    pagination : true
	});

	//=================================== Slide Services  ==================================//
 	$("#slide-features").owlCarousel({
		autoPlay: false,
		items : 2,
		navigation: false,
		autoHeight : true,
		itemsDesktop : [1600,2],
		itemsDesktopSmall : [1024,2],
		itemsMobile : [768,1],
		pagination: true
	});

  	//=================================== Carousel Sponsors  ================================//
 	$("#sponsors").owlCarousel({
      autoPlay: 3200,
       items : 6,
       navigation: true,
       itemsDesktopSmall : [1024,4],
       itemsTablet : [768,3],
       itemsMobile : [500,2],
       pagination: false
	});

	//=================================== Carousel testimonials  ================================//
	$("#testimonials").owlCarousel({
		autoPlay: false,
		items : 1,
		navigation: true,
		autoHeight : true,
		singleItem: true,
		pagination: false
	});

	//=================================== Slide Services  ================================//
	$("#slide-team").owlCarousel({
		items : 1,
		autoPlay: false,
		navigation : true,
		autoHeight : true,
		slideSpeed : 400,
		singleItem: true,
		pagination : false
	});

	//=================================== Subtmit Form  ====================================//
	$('.form-contact').submit(function(event) {
	    event.preventDefault();
	    var url = $(this).attr('action');
	    var datos = $(this).serialize();
	    $.get(url, datos, function(resultado) {
	    	$('.result').html(resultado);
		});
 	});

	//=================================== Subtmit Form Newslleter ===========================//
	$('#newsletterForm').submit(function(event) {
	    event.preventDefault();
	    var url = $(this).attr('action');
	    var datos = $(this).serialize();
	    $.get(url, datos, function(resultado) {
	        $('#result-newsletter').html(resultado);
		});
	});

	//=================================== Ligbox  ===========================================//
	$('.fancybox').fancybox({
		'overlayOpacity'	:  0.7,
		'overlayColor'		: '#000000',
		'transitionIn'		: 'elastic',
		'transitionOut'		: 'elastic',
    	'easingIn'			: 'easeOutBack',
    	'easingOut'      	: 'easeInBack',
		'speedIn'         	: '700',
		'centerOnScroll'	: true,
		'titlePosition'     : 'over'
	});

	//=================================== Tooltips ========================================//
	// tooltip demo
    $('.sponsors, .social, .icons-work, .tooltip-hover').tooltip({
      selector: "[data-toggle=tooltip]",
      container: "body"
   	});

    //=================================== Hover Efects =====================================//
	$('.feature-element li, .boxes-info, .item-table').hover(function() {
		$(this).toggleClass('animated pulse');
	});

    //================================== Scroll Efects =====================================//
  	$(window).scroll(function() {
	    $('.animation-services .icons li, .icon-section').each(function(){
	        var imagePos = $(this).offset().top;
	         var topOfWindow = $(window).scrollTop();
	          if (imagePos < topOfWindow+500) {
	              $(this).addClass("animated bounceInUp").css('opacity' , '1');
	              }
	        });

	    $('.animation-services .image-big').each(function(){
			var imagePos = $(this).offset().top;
			var topOfWindow = $(window).scrollTop();
				if (imagePos < topOfWindow+500) {
           		$(this).addClass("animated bounceInUp").css('opacity' , '1');
			}
		});
	});

	//=================================== Scrollbar  ======================================//
	$(".box").mCustomScrollbar({
        scrollButtons:{
			enable:true
		},
		theme:"dark-2"
    });

	//=================================== Totop  ==========================================//
  	$().UItoTop({
		scrollSpeed:500,
		easingType:'linear'
	});

	//=================================== Portfolio Filters  ==============================//
	$(window).load(function(){
     var $container = $('.portfolioContainer');
     $container.isotope({
      filter: '*',
          animationOptions: {
          duration: 750,
          easing: 'linear',
          queue: false
        }
     });

    $('.portfolioFilter a').click(function(){
      $('.portfolioFilter .current').removeClass('current');
      $(this).addClass('current');
       var selector = $(this).attr('data-filter');
       $container.isotope({
        filter: selector,
               animationOptions: {
               duration: 750,
               easing: 'linear',
               queue: false
             }
        });
       return false;
      });
	});

});

function mybysReceiveMessage(event)
{
  // Ensure the message is from our iframe
  if (event.origin.indexOf('mybys.com') != -1) {
      var message = JSON.parse(event.data);
      // Look for redirect action
      if (message['action'] == 'redirect') {
        url = message['url']
        if (url) {
          window.location = url;  
        }
      }
  }
}
window.addEventListener("message", mybysReceiveMessage, false);


// Function to add our query string parameter to the join iframe
$( document ).ready(function() {
  var location = window.location.toString();
  var querystring = location.split('?')[1];
  if (querystring) {
    $( "#mybys_join_iframe" ).attr('src', $( "#mybys_join_iframe" ).attr('src') + '&' + querystring);
  }
});
// Custom scripts for this project
const AUTOCONNECT = 'auto';
const CONFIRMPASSWORD = 'confirm_password';
const EMAIL = 'email';
const JOINNAME = 'joinName';
const WEBCALLNAME = 'webcallName';
const METHOD = 'method';
const PASSWORD = 'password';
const RETURNURL = 'returnURL';
const SESSION = 'session';
const TOKEN = 'token';
const USERNAME = 'username';

const userMessage = {
    'download.mybys':{
        'en': "The session launcher download should start automatically. If it doesn’t, <a href=\"{url}\">download now</a>.",
        'fr': "Le téléchargement du connecteur de session devrait démarrer automatiquement. Si ce n’est pas le cas, <a href=\"{url}\">téléchargez maintenant</a>."
    }
}

const joinErrors = {
    'router.session.not_active': {
        'en': "The session has not started.",
        'fr': "La session n'a pas encore commencée."
    },
    'router.session.invalid_password': {
        'en': "Missing or invalid password.",
        'fr': "Mot de passe manquant ou invalide."
    },
    default: {
        'en': "Connexion error. Please contact the support team.",
        'fr': "Erreur de connection. Veuillez contacter l'équipe de support."
    },
    error: 'Error'
}

const loginErrors = {
    'api.error.user.has_no_account': {
        'en': "Login failed.",
        'fr': "La connexion a échoué."
    },
    'api.error.login.password.invalid': {
        'en': "Login failed.",
        'fr': "La connexion a échoué."
    },
    default: {
        'en': "Connexion error. Please contact the support team.",
        'fr': "Erreur de connection. Veuillez contacter l'équipe de support."
    },
    error: 'Error'
}

const resetPasswordMessage = {
    'en': "An email has been sent to you.",
    'fr': "Un courriel vous a été envoyé."
}

const resetPasswordErrors = {
    'api.error.element.not_found': {
        'en': "No account was found, please try again.",
        'fr': "Aucun compte n'a été trouvé, veuillez réesayer."
    },
    default: {
        'en': "Reset password error. Please contact the support team.",
        'fr': "Erreur de réinitialisation du mot de passe. Veuillez contacter l'équipe de support."
    },
    error: 'Error'
}

const setPasswordMessage = {
    'en': "Password updated. Redirecting to login page",
    'fr': "Mot de passe mis à jour. Redirection vers la page de connection"
}

const setPasswordErrors = {
    'api.error.user.need_strong_password': {
        'en': "Minimum 8 characters, at lease one letter UPPERCASE, one LOWERCASE and one non-letter char.",
        'fr': "Minimum 8 caractères, au moins une lettre en majuscule, une en minuscule et un caractère numérique ou spécial."
    },
    'api.error.login.authorization.invalid_token': {
        'en': "Invalid or obsolete link.",
        'fr': "Lien non valide ou obsolète."
    },
    default: {
        'en': "Set Password error. Please contact the support team.",
        'fr': "Erreur de changement du mot de passe. Veuillez contacter l'équipe de support."
    },
    error: 'Error'
}
